import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fecha4',
    standalone: true
})
export class Fecha4Pipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      return `${this.genFecha(value)}`;
    } else {
      return null;
    }
  }

  genFecha(value: string): String{
    let dateParts = value.split('T');
    let fecha: string;
    let fechaTemp = dateParts[0];
    let datePartsTemp = fechaTemp.split('-');
    fecha = `${datePartsTemp[2]}-${datePartsTemp[1]}-${datePartsTemp[0]}`;
    return fecha;
  }

}
