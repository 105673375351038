import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fecha5',
    standalone: true
})
export class Fecha5Pipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      return `${this.genFecha(value)}`;
    } else {
      return null;
    }
  }

  genFecha(value: string): String{
    let dateParts = value.split('/');
    let fecha: string;
    fecha = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return fecha;
  }

}
